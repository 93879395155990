import api from './api'

export default {
  salvar(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/adesao/enviartermo/pdfemail', body, config)
  },
  buscarContrato(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/adesao/buscarContrato/', body, config)
  },

  buscarCliente(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/adesao/dadosCliente', body, config)
  },
  salvarDadosCliente(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/adesao/salvarDadosClientes', body, config)
  },
  buscarContratoDoCliente(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/adesao/buscarContratoDoCliente', body, config)
  },

  salvarAssinaturaCliente(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/adesao/salvarAssinaturaCliente', body, config)
  },
  buscarTodosOsContratos(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/adesao/buscarTodosOsContratos', body, config)
  },

  enviarLinkContrato(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/adesao/enviarLinkContrato', body, config)
  },
  salvarContrato(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/adesao/salvarcontrato', body, config)
  },
  buscarContratosCompletos(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/adesao/buscarContratosCompletos', body, config)
  },

  deletarContrato(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/adesao/deletarContrato', body, config)
  },
  editarContrato(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/adesao/editarContrato', body, config)
  },

  buscarDadosClientesSalvo(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/adesao/buscarDadosClientesSalvo', body, config)
  },
  buscarStakeHolders(body){
    const config = {
      headers: { 'x-access-token': `${localStorage.getItem('tktaskcheck')}`}
    }
    return api().post('/adesao/buscarStakeHolders', body, config)
  },
}