<template>
  <v-container>
    <div class="divContrato">
      <input type="text" class="inputName" placeholder="Nome do contrato" v-model="titulo"/>
      <p style="margin: 5px 0 20px 0">Tags de Substituição Automáticas: @nomeCliente, @nomeFantasia, @razaoSocial, @cnpj, @cep, @cidade, @rua, @bairro, @nomeCliente, @estado, @razaoSocial, @numeroCasa, @assinatura, @cidade e @stackholders:</p>
      <div style="margin: 10px 0 0 0;">
        <vue-editor v-model="contrato"></vue-editor>
      </div>
      <div style="display: flex; justify-content: end;">
        <button class="button" style="width: 150px!important;" @click="$router.push('/listar/contratos')">Voltar</button>
        <button class="button" style="margin-left: 10px;" @click="salvar">{{ mensagemBotaoSalvar }}</button>
      </div>
    </div>
    <v-dialog v-model="alerta" width="300px" max-width="50vw" persistent>
      <div style="background-color:white;width:100%;display:flex;flex-direction:column; padding: 10px; justify-content: center; align-items: center;text-align: center;">
          <h2>Atenção</h2>
          <p>{{ mensagem }}</p>
          <button class="button" @click="() => this.alerta = false">OK</button>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
 import { VueEditor } from 'vue2-editor'
 import termo_de_adesaoService from '../../services/termo_de_adesao.service';
  export default {
    components: {
    VueEditor
  },
    data() {
      return {
        contrato: null,
        titulo: null,
        alerta: false,
        mensagem: null,
        mensagemBotaoSalvar: 'Salvar',
        idContrato: null,
      }
    },
    methods: {
      async salvar() {
        this.mensagemBotaoSalvar = 'Aguarde...'
        const body = {
          titulo: this.titulo,
          contrato: this.contrato,
          id: this.idContrato,
        }
        console.log(body)
        if(!this.titulo || !this.contrato || !this.idContrato) {
          this.mensagemBotaoSalvar = 'Salvar'
          return;
        }
        try {     
         await termo_de_adesaoService.editarContrato(body);
         this.mensagem = 'Contrato salvo com sucesso :)'
         this.alerta = true;
         this.mensagemBotaoSalvar = 'Salvar'
        } catch (error) {
          if(error.response.data.message === 'Invalid fields!') {
            this.mensagem = 'Preencha todos os campos obrigatórios!';
            this.alerta = true;
          }
          this.mensagemBotaoSalvar = 'Salvar'
          console.log(error.response.data)
        }
      }
    },

    mounted() {
      this.contrato = this.$route.params.contrato;
      this.titulo = this.$route.params.titulo;
      this.idContrato = this.$route.params.id;
    }
  }
</script>

<style scoped>
.divContrato {
  margin: 0 auto 5em auto;
  width: 900px;
  max-width: 80vw;
  box-shadow:  4px 4px #d9d9d9;
  padding: 3em 1em 3em 1em;
  border-radius: 30px;
  background-color: #f9f9f2;
}

.inputName {
  background-color: white;
  border: 1px solid rgb(122, 122, 122);
  margin: 0 0 10px;
  border-radius: 5px;
  padding: 8px;
  width: 100%;
}

.button {
  background-color: #3A78F2;
  color: white;
  padding: 5px;
  width: 100px;
  border-radius: 5px;
  margin: 10px 0 0 0;
}
</style>